import React, {useEffect, useState, useRef} from 'react';
import { Link, useNavigate,  useParams, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";

import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpDown } from "@fortawesome/free-solid-svg-icons";

import ImageAdd from "../../component/ImageAdd";
import FileAdd from "../../component/FileAdd";
import * as api from "../../config/api"

function Set2() {
    //유저정보
    const user = useSelector((state) => state.user);

    const navigate = useNavigate();

    const [tab, setTab] = useState('국어');
    const [school, setSchool] = useState('초등');
    const [year, setYear] = useState("");
    const [detail, setDetail] = useState({subjectSeq:-1,view:1,viewEbook:1,viewDvd:1,viewData:1,urlEbook:"",image:""});

    const [reset, setReset] = useState(0);

    const [form_file1, setFormFile1] = useState("");
    const [form_orifile1, setFormOriFile1] = useState("");
    const [form_file2, setFormFile2] = useState("");
    const [form_orifile2, setFormOriFile2] = useState("");
    const [form_thumbnail, setFormThumbnail] = useState("");


    const [year_list, setYearList] = useState([]);
    const [list, setList] = useState([]);

    const dragItem = useRef(); // 드래그할 아이템의 인덱스
    const dragOverItem = useRef(); // 드랍할 위치의 아이템의 인덱스

    const dragStart = (e, position) => {
        dragItem.current = position;
    };

    const dragEnter = (e, position) => {
        dragOverItem.current = position;
    };

    // 드랍 (커서 뗐을 때)
    const drop = (par) => {
        const startSort = dragOverItem.current;
        const endSort = dragItem.current;
        const newList = [...list];
        const dragItemValue = newList[dragItem.current];
        newList.splice(dragItem.current, 1);       
        newList.splice(dragOverItem.current, 0, dragItemValue);
        dragItem.current = null;
        dragOverItem.current = null;
        if(startSort != endSort){
            setList(
                newList.map(val =>
                    val.subjectSeq === par.subjectSeq ? { ...val, subjectSeq: "move" } : val
                )
            );
            //드래그 순간 name이 같아져서 셀렉트 박스 체크 풀림 이동시 move처리 놓는 순간 다시 자기 번호로 변경
            setTimeout(()=>{
                setList(
                    newList.map(val =>
                        val.subjectSeq === par.subjectSeq ? { ...val, subjectSeq: par.subjectSeq } : val
                    )
                );
                let seqs = '';
                newList.map(val => {
                    seqs += ","+val.subjectSeq;
                })
                api.putData("/admin/curriculum/subject/sort", {},{
                    seqs:seqs
                },user.token).then(res=>{
                    if(res){

                    }
                });
            },100)
        }
    };

    const onList = (tab,school,year) => {


        api.getData("/admin/curriculum/subject", {
            subject:tab,
            school:school,
            year:year
        },user.token).then(res=>{
            if(res && res?.code === 'ERR_NETWORK'){
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
            if(res && res.data.status==='SUCCESS'){
                setList(res.data.data);
            }else{
                window.alert("로그인 기간이 만료되었습니다. 재로그인 해주세요.");
                navigate("/")
                return;
            }
        });
    }

    useEffect(() => {
        setDetail({subjectSeq:-1,view:1,viewEbook:1,viewDvd:1,viewData:1,name:"",urlEbook:"",sort:0,fileDvd:"",fileData:"",image:""});
        api.getData("/curriculum/year", {
  
        },{}).then(res=>{
            if(res && res.data.status==='SUCCESS'){
                setYearList(res.data.data);
            }
        });
        onList(tab,school,year);
    }, []);


    const onSave = () => {
        const formData = new FormData();
        if(detail.subjectSeq > 0 ){
            formData.append("subjectSeq",detail.subjectSeq);
        } else{
            const sort =  list.filter((par,key) => par.subject == tab && par.school == school && par.yearSeq == year ).length;
            formData.append("sort",(sort + 1));
        }

        formData.append("subject",tab);
        formData.append("school",school);
        formData.append("yearSeq",year);
        formData.append("name",detail.name);
        formData.append("view",detail.view);
        formData.append("viewEbook",detail.viewEbook);
        formData.append("viewDvd",detail.viewDvd);
        formData.append("viewData",detail.viewData);
        formData.append("urlEbook",detail.urlEbook);
        formData.append("fileDvd",detail.fileDvd);
        formData.append("fileData",detail.fileData);
        formData.append("fileDvdOri",detail.fileDvd);
        formData.append("fileDataOri",detail.fileData);

        //formData.append("files1",detail.fileDvd);
        //formData.append("files2",detail.fileData);
        
        formData.append("images",detail.image);

        api.fileUpload("/admin/curriculum/subject",formData,user.token).then(res=>{

            const ret = res.data.data;
            
            if(res.data.status=="SUCCESS"){
                if(detail.subjectSeq > 0 ){
                    setList(
                        list.map(val => 
                            val.subjectSeq === detail.subjectSeq ? ret : val
                        )
                    );
                    window.alert("수정되었습니다.");
                }else{
                    setList([...list, ret]);
                    window.alert("저장되었습니다.");
                }
                //onSetDetail(-1);
                
            }
        });
    }



    const onRemove = (seq) => {
        if(window.confirm("정말로 삭제하시겠습니까?")){
            api.deleteData("/admin/curriculum/subject", {
                id:seq
            },user.token).then(res=>{
                if(res && res.data.status == "SUCCESS"){
                    setList(list.filter(val => val.subjectSeq !== seq));
                    onSetDetail(-1);
                    window.alert("삭제되었습니다.");
                }
            });
        }
    }

    const onDetail = (val) => {
        setReset(val.subejctSeq);  
        setDetail(val);   
    }
    const onSetDetail = (seq) => {

        setDetail({subjectSeq:seq,view:1,viewEbook:1,viewDvd:1,viewData:1,name:"",sort:0,fileDvdOri:"",fileDataOri:"",imageOri:"",fileDvd:"",fileData:"",image:"",ebookUrl:""});
        //setReset(seq);    
        setReset(Date.now());        
    }
    
    const onTab = (txt) => {
        setTab(txt);
        setSchool("");
        setYear("");
        //onList(txt,"","");
        //const year = year_list.find(par => par.subject == txt && par.school == school )?.seq
        //setYear(year ? year : "");
    }
    const onSchool = (value) => {
        setSchool(value);
        setYear("");
        //nList(tab,value,"");
        //const year = year_list.find(par => par.subject == tab && par.school == value )?.seq
        //setYear(year ? year : "");
    }

    const onYear = (value) => {
        setYear(value);
        onList(tab,school,value);
    }

    

   

    return (
        <div>
            <h1 className="head">교과 설정</h1>
             <div className="tab">
                <button type="button" className={tab=='국어' ? "active" : ""}  onClick={()=>onTab('국어')}>국어</button>
                <button type="button" className={tab=='사회' ? "active" : ""} onClick={()=>onTab('사회')}>사회</button>
                <button type="button" className={tab=='미술' ? "active" : ""}  onClick={()=>onTab('미술')}>미술</button>

            </div>
            <div className="tab_box">
                <div className="tab_category dragndrop">                   
                    <dl>
                        <dt>
                            <select onChange={(e)=>onSchool(e.target.value)} value={school}>
                                <option value="">학교급</option>
                                <option value="초등">초등</option>
                                <option value="중학">중학</option>
                                <option value="고등">고등</option>
                                <option value="인정">인정</option>
                            </select>
                            <select onChange={(e)=>onYear(e.target.value)} value={year}>
                                <option value="">개정연도</option>
                                {year_list.map((val)=>(
                                    <option value={val.seq} className={tab==val.subject && school==val.school ? 'show' : 'hide'}>{val.year}년</option>
                                ))}

                            </select>
                            { (year!="") &&
                            <button type="button" className="btn-primary" onClick={(e)=>onSetDetail(0)}>추가</button>
                            }
                        </dt>
                        {list.map((val, index) => (
                        <dd 
                            className={tab==val.subject && school==val.school && year==val.yearSeq ? 'show' : 'hide'}
                            key={index}
                            draggable
                            onDragStart={(e) => dragStart(e, index)}
                            onDragEnter={(e) => dragEnter(e, index)}
                            onDragEnd={(e) => drop(val)}
                            onDragOver={(e) => e.preventDefault()}
                        >
                            <span>[{val.subjectSeq}] </span>
                            <i><FontAwesomeIcon icon={faUpDown} /></i>
                            <div><span class="click" onClick={(e)=>onDetail(val)}>{val.name}</span></div></dd>
                        
                        ))}
                    </dl>
                </div>
                <div className="tab_detail">
                    <table className={detail.subjectSeq >= 0 ? 'detail' : 'detail hide'}>
                        <tbody>
                            <tr>
                                <th>교과명</th>
                                <td className="text-flex"> 
                                    <input type="text" value={detail.name} onChange={(e)=>setDetail({...detail, name: e.target.value})} />
                                    <label className="radiobox"><input type="radio" checked={detail.view===1} onClick={()=>setDetail({...detail, view: 1})}/><span>노출</span></label>
                                    <label className="radiobox"><input type="radio" checked={detail.view===0} onClick={()=>setDetail({...detail, view: 0})}/><span>비노출</span></label>
                                </td>
                            </tr>

                            <tr>
                                <th>이미지</th>
                                <td>
                                
                                <ImageAdd folder="subject" form_thumbnail={detail.image} setFormThumbnail={(e)=>{setDetail({...detail, image:e})}} form_orithumbnail={detail.imageOri}  width={200} height={200} resize={1} id={reset} />
                                </td>
                            </tr>
                            <tr>
                                <th>이북보기</th>
                                <td className="text-flex"> 
                                    <label className="radiobox"><input type="radio" checked={detail.viewEbook===1} onClick={()=>setDetail({...detail, viewEbook: 1})} /><span>노출</span></label>
                                    <label className="radiobox"><input type="radio" checked={detail.viewEbook===0} onClick={()=>setDetail({...detail, viewEbook: 0})} /><span>비노출</span></label>
                                    <input type="text" value={detail.urlEbook} onChange={(e)=>setDetail({...detail, urlEbook: e.target.value})} />
                                </td>
                            </tr>
                            <tr>
                                <th>DVD다운</th>
                                <td className="text-flex"> 
                                    <label className="radiobox"><input type="radio" checked={detail.viewDvd===1} onClick={()=>setDetail({...detail, viewDvd: 1})}  /><span>노출</span></label>
                                    <label className="radiobox"><input type="radio" checked={detail.viewDvd===0} onClick={()=>setDetail({...detail, viewDvd: 0})}  /><span>비노출</span></label>
                                    
                                    <input type="text" value={detail.fileDvd} onChange={(e)=>setDetail({...detail, fileDvd: e.target.value})} placeholder="확장자를 포함한 파일명을 입력하세요."/>
                                    {/*<FileAdd form_file={detail.fileDvd} setFormFile={(e)=>{setDetail({...detail, fileDvd:e})}} form_orifile={detail.fileDvdOri} id={reset ? reset : -1} />*/}
                                    
                                </td>
                            </tr>
                            <tr>
                                <th>자료 한번에</th>
                                <td className="text-flex"> 
                                    <label className="radiobox"><input type="radio" checked={detail.viewData===1} onClick={()=>setDetail({...detail, viewData: 1})} /><span>노출</span></label>
                                    <label className="radiobox"><input type="radio" checked={detail.viewData===0} onClick={()=>setDetail({...detail, viewData: 0})} /><span>비노출</span></label>
                                   
                                    <input type="text" value={detail.fileData} onChange={(e)=>setDetail({...detail, fileData: e.target.value})} placeholder="확장자를 포함한 파일명을 입력하세요."/>
                                    {/*<FileAdd form_file={detail.fileData} setFormFile={(e)=>setDetail({...detail, fileData:e})} form_orifile={detail.fileDataOri} id={reset ? reset : -1} />*/}
                                    
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan="2">
                                    { detail.subjectSeq == 0 ?
                                    <button type="button" className="btn-warning" onClick={(e)=>setDetail({...detail, subjectSeq:-1})}>취소</button>
                                    :
                                    <button type="button" className="btn-danger" onClick={(e)=>onRemove(detail.subjectSeq)}>삭제</button>
                                    }

                                    <button type="button" className={detail.subjectSeq > 0 ? "btn-info": "btn-primary"} onClick={(e)=>onSave()}>{detail.subjectSeq > 0 ? '수정': '저장'}</button>
                                    {/*<button type="button" className="btn-success" >미리보기</button>*/}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Set2;